import PropTypes from 'prop-types'
import React from 'react'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'
import SiteLinks from 'routes/about/components/site-links'

// Page Components
import AboutPocketeer from './components/about-pocketeer'

function Pocketeer({ data }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <SiteLinks activePage="pocketeers" />
      <AboutPocketeer data={data} />
    </Layout>
  )
}
Pocketeer.propTypes = {
  data: PropTypes.object,
}
export default Pocketeer
