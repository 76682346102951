import PropTypes from 'prop-types'
import React from 'react'
import { Box, Container, Columns, Hero } from 'react-bulma-components'
import { GatsbyImage } from 'gatsby-plugin-image'

function AboutPocketeer({ data }) {
  return (
    <Hero className="is-small">
      <Hero.Body>
        <Container className="container content">
          <Columns>
            <Columns.Column className="is-offset-1 is-6 is-vcentered">
              <h1 className="title is-size-1">
                {data.pocketeer.firstName} {data.pocketeer.lastName}
              </h1>
              <h3 className="subtitle">{data.pocketeer.role}</h3>
              <div
                className=""
                dangerouslySetInnerHTML={{
                  __html: data.pocketeer.description
                    ? data.pocketeer.description
                    : `Bear with us please, we're just preparing a bio for ${data.pocketeer.firstName}.`,
                }}
              />
            </Columns.Column>
            <Columns.Column className="is-4 is-vcentered">
              <Box className="is-rounded has-shadow">
                <GatsbyImage
                  image={data.pocketeer.mainImage.gatsbyImageData}
                  alt={`${data.pocketeer.firstName} | Pocketworks`}
                />
              </Box>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  )
}
AboutPocketeer.propTypes = {
  data: PropTypes.object,
}
export default AboutPocketeer
