/* eslint-disable react/prop-types */
import React from 'react'

import { graphql } from 'gatsby'

// Meta Component
import Meta from 'components/Meta'

// BlogCategoryPage Route
import Pocketeer from 'routes/templates/pocketeer'

// Gatsby SEO Head
export function Head({ data }) {
  return (
    <Meta tags={data.pocketeer.seoMetaTags.tags} hideTitle hideMeta>
      <title>
        {data.pocketeer.firstName}, {data.pocketeer.role}
      </title>
      <meta name="description" content={data.pocketeer.description} />
    </Meta>
  )
}

export default Pocketeer

export const query = graphql`
  query PocketeerQuery($slug: String!) {
    pocketeer: datoCmsAuthor(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      name
      firstName
      lastName
      nickname
      description
      slug
      role
      mainImage {
        url
        gatsbyImageData(
          placeholder: TRACED_SVG
          imgixParams: {
            fit: "crop"
            crop: "bottom"
            w: "1080"
            h: "900"
            fm: "jpg"
            sat: -5
            auto: "compress"
          }
        )
      }
    }
  }
`
